var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { AuthService, EventsService, MerchantStoreService, WineryStoreService } from '../core';
import { AppsService } from '../core/services/apps.service';
import { PromosService } from '../core';
import { AppOp, Constants, EventsOp, MerchantOp, ProducerOp, PromoOp } from '../scripts';
export class HomeComponent {
    constructor(router, dialog, snackBar, auth, wineryStore, merchantStore, eventService, promoService, appService) {
        this.router = router;
        this.dialog = dialog;
        this.snackBar = snackBar;
        this.auth = auth;
        this.wineryStore = wineryStore;
        this.merchantStore = merchantStore;
        this.eventService = eventService;
        this.promoService = promoService;
        this.appService = appService;
        this._destroy$ = new Subject();
        this.tabIndex = 0;
        this.activeIndex = 0;
        this.AppTypes = Constants.AppTypes;
        this.wineries$ = this.wineryStore.wineries$.pipe(map(p => p.filter(w => w.category === Constants.ProdCategory.WINERY)));
        this.wineriesRaw$ = this.wineryStore.wineriesRaw$.pipe(map(p => p.filter(w => w.category === Constants.ProdCategory.WINERY)));
        this.distilleries$ = this.wineryStore.wineries$.pipe(map(p => p.filter(w => w.category === Constants.ProdCategory.DISTILLERY)));
        this.distilleriesRaw$ = this.wineryStore.wineriesRaw$.pipe(map(p => p.filter(w => w.category === Constants.ProdCategory.DISTILLERY)));
        this.shops$ = this.merchantStore.merchants$.pipe(map(p => p.filter(m => m.category === Constants.MerchCategory.SHOP)));
        this.shopsRaw$ = this.merchantStore.merchantsRaw$.pipe(map(p => p.filter(m => m.category === Constants.MerchCategory.SHOP)));
        this.restaurants$ = this.merchantStore.merchants$.pipe(map(p => p.filter(m => m.category === Constants.MerchCategory.RESTAURANT)));
        this.restaurantsRaw$ = this.merchantStore.merchantsRaw$.pipe(map(p => p.filter(m => m.category === Constants.MerchCategory.RESTAURANT)));
        this.distributors$ = this.merchantStore.merchants$.pipe(map(p => p.filter(m => m.category === Constants.MerchCategory.DISTRIBUTOR)));
        this.distributorsRaw$ = this.merchantStore.merchantsRaw$.pipe(map(p => p.filter(m => m.category === Constants.MerchCategory.DISTRIBUTOR)));
        this.lodgings$ = this.merchantStore.merchants$.pipe(map(p => p.filter(m => m.category === Constants.MerchCategory.LODGING)));
        this.lodgingsRaw$ = this.merchantStore.merchantsRaw$.pipe(map(p => p.filter(m => m.category === Constants.MerchCategory.LODGING)));
        this.transports$ = this.merchantStore.merchants$.pipe(map(p => p.filter(m => m.category === Constants.MerchCategory.TRANSPORT)));
        this.transportsRaw$ = this.merchantStore.merchantsRaw$.pipe(map(p => p.filter(m => m.category === Constants.MerchCategory.TRANSPORT)));
        this.others$ = this.merchantStore.merchants$.pipe(map(p => p.filter(m => m.category === Constants.MerchCategory.OTHER)));
        this.othersRaw$ = this.merchantStore.merchantsRaw$.pipe(map(p => p.filter(m => m.category === Constants.MerchCategory.OTHER)));
    }
    ngOnInit() {
        this.wineryStore.lastError$.pipe(takeUntil(this._destroy$)).subscribe(error => {
            this.snackBar.open(error.formatted, 'dismiss', {
                duration: 8000,
                verticalPosition: 'top',
            });
        });
    }
    ngOnDestroy() {
        this._destroy$.next();
        this._destroy$.complete();
    }
    animationDone() {
        this.activeIndex = this.tabIndex;
    }
    eventsAction(event) {
        return __awaiter(this, void 0, void 0, function* () {
            const eventObj = event[1];
            switch (event[0]) {
                case EventsOp.EditEvent:
                    yield this.router.navigateByUrl(`/cms/events/${eventObj._id}`);
                    break;
                case EventsOp.AddEvent:
                    this.eventService.addEvent(eventObj);
                    break;
                case EventsOp.RemoveEvent:
                    this.eventService.deleteEvent(eventObj);
                    break;
            }
        });
    }
    appAction(action) {
        return __awaiter(this, void 0, void 0, function* () {
            const appObj = action[1];
            switch (action[0]) {
                case AppOp.EditApp:
                    yield this.router.navigateByUrl(`/cms/apps/${appObj._id}`);
                    break;
                case AppOp.AddApp:
                    this.appService.addApp(appObj);
                    break;
                case AppOp.RemoveApp:
                    this.appService.deleteApp(appObj);
                    break;
            }
        });
    }
    promoAction(action) {
        return __awaiter(this, void 0, void 0, function* () {
            const promoObj = action[1];
            switch (action[0]) {
                case PromoOp.AddPromo:
                    this.promoService.addPromo(promoObj);
                    break;
                case PromoOp.RemovePromo:
                    this.promoService.deletePromo(promoObj);
                    break;
                case PromoOp.SavePromo:
                    this.promoService.savePromo(promoObj);
                    break;
            }
        });
    }
    producerAction(event) {
        return __awaiter(this, void 0, void 0, function* () {
            switch (event[0]) {
                case ProducerOp.EditProducer:
                    {
                        const producer = event[1];
                        yield this.router.navigateByUrl(`/cms/wineries/${producer._id}`);
                    }
                    break;
                case ProducerOp.AddProducer:
                    {
                        const producer = event[1];
                        this.wineryStore.addWinery(producer);
                    }
                    break;
                case ProducerOp.FilterProducers:
                    this.wineryStore.wineryFilter = event[1];
                    break;
            }
        });
    }
    merchantAction(event) {
        return __awaiter(this, void 0, void 0, function* () {
            switch (event[0]) {
                case MerchantOp.EditMerchant:
                    {
                        const merchant = event[1];
                        yield this.router.navigateByUrl(`/cms/merchants/${merchant._id}`);
                    }
                    break;
                case MerchantOp.AddMerchant:
                    {
                        const merchant = event[1];
                        this.merchantStore.addMerchant(merchant);
                    }
                    break;
                case MerchantOp.FilterMerchants:
                    this.merchantStore.merchantFilter = event[1];
                    break;
            }
        });
    }
}
