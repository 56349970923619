<div *ngIf="merchants" fxLayout="column">
  <mat-radio-group
    *ngIf="canFilter"
    class="filter-group"
    fxFlex="50px"
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="10px"
    (change)="applyFilter()"
    [(ngModel)]="filter"
  >
    <span fxFlex="50%">
      <mat-radio-button fxFlex="33%" *ngFor="let filter of filters" [value]="filter.value">
        {{ filter.label }} ({{ counts[filter.value] }})
      </mat-radio-button>
    </span>
  </mat-radio-group>
  <div *ngIf="canFilter" fxLayout="row">
    <mat-form-field fxFlex="33%" appearance="fill">
      <mat-label>Search</mat-label>
      <input aria-label="Search" matInput type="text" [(ngModel)]="filterString" />
      <button aria-label="clear" mat-icon-button matSuffix (click)="filterString = ''">
        <mat-icon>clear</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <div id="merchant-list" class="content-container">
    <app-content-card
      *ngFor="let merchant of merchants | filterList: filterMerchants; let i = index"
      class="wine-list-card"
      [title]="merchant.name"
      [subTitle]="subtitle(merchant)"
      [image]="merchantImage(merchant)"
      [buttons]="buttons"
      (action)="merchantAction(merchant, $event)"
    ></app-content-card>
  </div>
</div>
<span class="button-row">
  <button *ngIf="canCreate" mat-raised-button color="primary" type="button" (click)="addMerchant()">
    Add New {{ name || category | titlecase }}
  </button>
  <button *ngIf="canInsert" mat-raised-button color="primary" type="button" (click)="insertMerchant()">
    Insert {{ name || category | titlecase }}
  </button>
</span>
