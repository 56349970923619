export class Constants {
  static readonly BottleSizes = {
    150: 'Glass (150ml)',
    187: 'Split (187.5ml)',
    375: 'Half (375ml)',
    750: 'Standard (750ml)',
    1500: 'Magnum (1.5L)',
    3000: 'Double Magnum (3.0L)',
    4500: 'Jeroboam (4.5L)',
    9000: 'Case (12 Standard Bottles)',
  };

  static readonly AgeRanges = {
    '0-21': { min: 0, max: 21 },
    '22-37': { min: 22, max: 37 },
    '38-53': { min: 38, max: 53 },
    '54-72': { min: 54, max: 72 },
    '73+': { min: 73 },
  };

  static readonly AgeGroups = [...Object.keys(Constants.AgeRanges), 'Unknown'];
  static readonly Genders = ['Male', 'Female', 'Prefer not to say', 'Unknown'];
  static readonly BevCategory = {
    WINE: 'wine',
    BEER: 'beer',
    SPIRIT: 'spirit',
    MENU: 'menu',
    OIL: 'oil',
  };

  static readonly ProdCategory = {
    WINERY: 'winery',
    DISTILLERY: 'distillery',
    BREWERY: 'brewery',
  };

  static readonly Prod2Collection = {
    [Constants.ProdCategory.WINERY]: 'wines',
    [Constants.ProdCategory.DISTILLERY]: 'spirits',
  };

  static readonly Bev2Collection = {
    [Constants.BevCategory.WINE]: 'wines',
    [Constants.BevCategory.SPIRIT]: 'spirits',
    [Constants.BevCategory.OIL]: 'oils',
  };

  static readonly MerchCategory = {
    SHOP: 'shop',
    RESTAURANT: 'restaurant',
    DISTRIBUTOR: 'distributor',
    LODGING: 'lodging',
    TRANSPORT: 'transport',
    OTHER: 'other',
  };

  static readonly ReportTypes = {
    PRODUCER: 'winery',
    BEVERAGE: 'wine',
    TASTING: 'tasting',
    PROMO: 'promo',
    EVENT: 'event',
    MERCHANT: 'merchant',
    MERCH_PRODUCT: 'merchprod',
    MERCH_TASTING: 'merchtaste',
    MERCH_PROMO: 'merchpromo',
    PROMO_REQ: 'promoreq',
  };

  static readonly AppTypes = {
    WINERY: 'winery',
    DISTILLERY: 'distillery',
    SHOP: 'shop',
    RESTAURANT: 'restaurant',
    DISTRIBUTOR: 'distributor',
    LODGING: 'lodging',
    TRANSPORT: 'transport',
    OTHER: 'other',
    EVENT: 'event',
    PROMO: 'promo',
    APP: 'app',
  };

  static readonly Icon4Type = {
    [Constants.AppTypes.WINERY]: 'wine_bar',
    [Constants.AppTypes.DISTILLERY]: 'local_bar',
    [Constants.AppTypes.SHOP]: 'store_front',
    [Constants.AppTypes.RESTAURANT]: 'restaurant_menu',
    [Constants.AppTypes.DISTRIBUTOR]: 'local_shipping',
    [Constants.AppTypes.LODGING]: 'hotel',
    [Constants.AppTypes.TRANSPORT]: 'airport_shuttle',
    [Constants.AppTypes.OTHER]: 'shopping_bag',
    [Constants.AppTypes.EVENT]: 'festival',
    [Constants.AppTypes.PROMO]: 'local_activity',
    ['app']: 'phone_android',
  };

  static readonly WineTypes = [
    { value: 'red', name: 'Red' },
    { value: 'white', name: 'White' },
    { value: 'rose', name: 'Rosé' },
    { value: 'dessert', name: 'Dessert' },
    { value: 'sparkling', name: 'Sparkling' },
    { value: 'fortified', name: 'Fortified' },
  ];

  static readonly SpiritTypes = [
    { value: 'tequila', name: 'Tequila' },
    { value: 'vodka', name: 'Vodka' },
    { value: 'gin', name: 'Gin' },
    { value: 'whiskey', name: 'Whiskey' },
    { value: 'rum', name: 'Rum' },
  ];

  static readonly BeerTypes = [
    { value: 'lager', name: 'Lager' },
    { value: 'pilsner', name: 'Pilsner' },
    { value: 'ipa', name: 'IPA' },
    { value: 'stout', name: 'Stout' },
    { value: 'porter', name: 'Porter' },
  ];

  static readonly OilTypes = [{ value: 'olive', name: 'Olive' }];

  static readonly ContextTypes = {
    PRODUCT: '0001',
    WINE: '0002',
    COLLECTION: '0003',
    TASTING: '0004',
    MENU: '0005',
    EVENT: '0006',
    WINERY: '0007',
    PROMO: '0008',
    SELECTION: '0009',
    SPIRIT: '0010',
    DISTILLERY: '0011',
    SHOP: '0012',
    RESTAURANT: '0013',
    DISTRIBUTOR: '0014',
    PRODUCERLIST: '0015',
    MERCHANTLIST: '0016',
    OIL: '0017',
  };

  static readonly ContextNames = {
    '0001': 'product',
    '0002': 'wine',
    '0003': 'collection',
    '0004': 'tasting',
    '0005': 'menu',
    '0006': 'event',
    '0007': 'winery',
    '0008': 'promo',
    '0009': 'selection',
    '0010': 'spirit',
    '0011': 'distillery',
    '0012': 'shop',
    '0013': 'restaurant',
    '0014': 'distributor',
    '0015': 'producerlist',
    '0016': 'merchantlist',
    '0017': 'oil',
  };

  static readonly Category2Context = {
    [Constants.BevCategory.WINE]: Constants.ContextTypes.WINE,
    [Constants.BevCategory.SPIRIT]: Constants.ContextTypes.SPIRIT,
    [Constants.ProdCategory.WINERY]: Constants.ContextTypes.WINERY,
    [Constants.BevCategory.MENU]: Constants.ContextTypes.MENU,
    [Constants.ProdCategory.DISTILLERY]: Constants.ContextTypes.DISTILLERY,
    [Constants.MerchCategory.SHOP]: Constants.ContextTypes.SHOP,
    [Constants.MerchCategory.RESTAURANT]: Constants.ContextTypes.RESTAURANT,
    [Constants.MerchCategory.DISTRIBUTOR]: Constants.ContextTypes.DISTRIBUTOR,
    product: Constants.ContextTypes.PRODUCT,
    tasting: Constants.ContextTypes.TASTING,
    promo: Constants.ContextTypes.PROMO,
    event: Constants.ContextTypes.EVENT,
  };

  static readonly LinkActions = [
    { label: 'External Browser', value: 'browser' },
    { label: 'Embedded Webview', value: 'embedded' },
    { label: 'HTML Content', value: 'html' },
    { label: 'Text Content', value: 'text' },
    { label: 'Winery Screen', value: 'winery' },
    { label: 'Wine Screen', value: 'wine' },
    { label: 'Promotion Screen', value: 'promo' },
    { label: 'Winery Wine List', value: 'wine-list' },
    { label: 'Event Screen', value: 'event' },
  ];

  static readonly LinkLabels = {
    browser: 'URL',
    embedded: 'URL',
    winery: 'Winery ID',
    'wine-list': 'Wine List ID',
    wine: 'Wine ID',
    promo: 'Promotion ID',
    html: 'Edit HTML Content',
    text: 'Edit Text Content',
  };

  static readonly WineFilters = [
    { label: 'Active', value: 'active' },
    { label: 'Inactive', value: 'inactive' },
    { label: 'All', value: 'all' },
  ];

  static readonly Perms = {
    CMS: 'cms',
    REPORTS: 'reports',
    STORIES: 'stories',
    IMPORT: 'import',
    NOTIFICATIONS: 'notifications',
    SYNC: 'sync',
    USERS: 'users',
  };

  static readonly ALL_PERMS = Object.values(Constants.Perms).reduce((all, perm) => ({ ...all, [perm]: true }), {});
  static readonly URLRegx = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z]{2,63}\b([-a-zA-Z0-9@:%_+.~#?&\/=]*)$/;
  static readonly OIDRegx = /^[a-f\d]{24}$/i;
  static readonly PhoneRegx = /^(\+[0-9]{0,3})?([0-9]{10})*$/;
  static readonly PostalRegx = /^[0-9a-zA-Z\- ]+$/;
  static readonly EmailRegx =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
}
