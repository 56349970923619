import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Promo } from '../models';
import { List } from 'immutable';
import { ApiService } from './api.service';
import { finalize } from 'rxjs/operators';

export interface PromoError {
  context: string;
  message: string;
  promo?: Promo;
  formatted: string;
}

@Injectable({
  providedIn: 'root',
})
export class PromosService {
  private _promos$: BehaviorSubject<List<Promo>> = new BehaviorSubject(List([]));
  private _reqs$: BehaviorSubject<List<Promo>> = new BehaviorSubject(List([]));
  private _fetchingPromos = false;
  private _fetched = false;
  private _fetchingReqs = false;
  private _fetchedReqs = false;

  private _lastError: Subject<PromoError> = new Subject<PromoError>();
  public lastError$: Observable<PromoError> = this._lastError.asObservable();

  constructor(private apiService: ApiService) {}
  get promos$() {
    if (!this._fetchingPromos && !this._fetched) {
      this._fetchingPromos = true;
      this.apiService
        .get('/promos')
        .pipe(
          finalize(() => {
            this._fetchingPromos = false;
            this._fetched = true;
          })
        )
        .subscribe(
          (promos) => {
            this._promos$.next(List(promos));
          },
          (error) => {
            this.setLastError('Retrieving promos', error.message);
          }
        );
    }
    return this._promos$.asObservable();
  }

  get reqs$() {
    if (!this._fetchingReqs && !this._fetchedReqs) {
      this._fetchingReqs = true;
      this.apiService
        .get('/promos/reqs')
        .pipe(
          finalize(() => {
            this._fetchingReqs = false;
            this._fetchedReqs = true;
          })
        )
        .subscribe(
          (reqs) => {
            this._reqs$.next(List(reqs));
          },
          (error) => {
            this.setLastError('Retrieving promo reqs', error.message);
          }
        );
    }
    return this._reqs$.asObservable();
  }

  private setLastError(context: string, message: string, promo?: Promo) {
    this._lastError.next({
      context,
      message,
      promo,
      formatted: `${context} failed because ${message}`,
    } as PromoError);
  }

  private updatePromoInList(promo: Promo) {
    const _promos = this._promos$.getValue();
    const index = _promos.findIndex((w) => w._id === promo._id);
    this._promos$.next(_promos.set(index, promo));
  }

  addPromo(promo: Promo): Observable<Promo> {
    const returnedPromo: BehaviorSubject<Promo> = new BehaviorSubject(promo);
    this.apiService.post('/promos', promo).subscribe(
      (newPromo) => {
        returnedPromo.next(newPromo);
        this._promos$.next(this._promos$.getValue().insert(0, newPromo));
      },
      (error) => {
        this.setLastError('Adding Promo', error.message, promo);
      }
    );

    return returnedPromo.asObservable();
  }

  deletePromo(promo: Promo): Observable<Promo> {
    const returnedPromo: BehaviorSubject<Promo> = new BehaviorSubject(promo);
    const promos = this._promos$.getValue();
    const index = promos.findIndex((s) => s._id === promo._id);
    if (index === -1) {
      throw this.setLastError('Deleting Promo', 'promo not in list', promo);
    } else {
      this.apiService.delete(`/promos/${promo._id}`).subscribe(
        () => {
          returnedPromo.next({} as Promo);
          this._promos$.next(promos.splice(index, 1));
        },
        (error) => {
          this.setLastError('Deleting promo', error.message, promo);
        }
      );
    }

    return returnedPromo.asObservable();
  }

  savePromo(promo: Promo): Observable<Promo> {
    const _promos = this._promos$.getValue();
    console.log(promo);
    const index = _promos.findIndex((s) => s._id === promo._id);
    const promo$: BehaviorSubject<Promo> = new BehaviorSubject<Promo>(_promos.get(index) as Promo);
    if (index === -1) {
      this.setLastError('Saving Promo', 'promo not in list', promo);
    } else {
      this.apiService.put(`/promos/${promo._id}`, promo).subscribe(
        (savedEvent) => {
          this.updatePromoInList(savedEvent);
          promo$.next(savedEvent);
        },
        (error) => {
          this.setLastError('Saving Event', error.message, promo);
        }
      );
    }
    return promo$.asObservable();
  }
}
