import { BehaviorSubject, Subject } from 'rxjs';
import { List } from 'immutable';
import { ApiService } from './api.service';
import { finalize } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
export class PromosService {
    constructor(apiService) {
        this.apiService = apiService;
        this._promos$ = new BehaviorSubject(List([]));
        this._reqs$ = new BehaviorSubject(List([]));
        this._fetchingPromos = false;
        this._fetched = false;
        this._fetchingReqs = false;
        this._fetchedReqs = false;
        this._lastError = new Subject();
        this.lastError$ = this._lastError.asObservable();
    }
    get promos$() {
        if (!this._fetchingPromos && !this._fetched) {
            this._fetchingPromos = true;
            this.apiService
                .get('/promos')
                .pipe(finalize(() => {
                this._fetchingPromos = false;
                this._fetched = true;
            }))
                .subscribe((promos) => {
                this._promos$.next(List(promos));
            }, (error) => {
                this.setLastError('Retrieving promos', error.message);
            });
        }
        return this._promos$.asObservable();
    }
    get reqs$() {
        if (!this._fetchingReqs && !this._fetchedReqs) {
            this._fetchingReqs = true;
            this.apiService
                .get('/promos/reqs')
                .pipe(finalize(() => {
                this._fetchingReqs = false;
                this._fetchedReqs = true;
            }))
                .subscribe((reqs) => {
                this._reqs$.next(List(reqs));
            }, (error) => {
                this.setLastError('Retrieving promo reqs', error.message);
            });
        }
        return this._reqs$.asObservable();
    }
    setLastError(context, message, promo) {
        this._lastError.next({
            context,
            message,
            promo,
            formatted: `${context} failed because ${message}`,
        });
    }
    updatePromoInList(promo) {
        const _promos = this._promos$.getValue();
        const index = _promos.findIndex((w) => w._id === promo._id);
        this._promos$.next(_promos.set(index, promo));
    }
    addPromo(promo) {
        const returnedPromo = new BehaviorSubject(promo);
        this.apiService.post('/promos', promo).subscribe((newPromo) => {
            returnedPromo.next(newPromo);
            this._promos$.next(this._promos$.getValue().insert(0, newPromo));
        }, (error) => {
            this.setLastError('Adding Promo', error.message, promo);
        });
        return returnedPromo.asObservable();
    }
    deletePromo(promo) {
        const returnedPromo = new BehaviorSubject(promo);
        const promos = this._promos$.getValue();
        const index = promos.findIndex((s) => s._id === promo._id);
        if (index === -1) {
            throw this.setLastError('Deleting Promo', 'promo not in list', promo);
        }
        else {
            this.apiService.delete(`/promos/${promo._id}`).subscribe(() => {
                returnedPromo.next({});
                this._promos$.next(promos.splice(index, 1));
            }, (error) => {
                this.setLastError('Deleting promo', error.message, promo);
            });
        }
        return returnedPromo.asObservable();
    }
    savePromo(promo) {
        const _promos = this._promos$.getValue();
        console.log(promo);
        const index = _promos.findIndex((s) => s._id === promo._id);
        const promo$ = new BehaviorSubject(_promos.get(index));
        if (index === -1) {
            this.setLastError('Saving Promo', 'promo not in list', promo);
        }
        else {
            this.apiService.put(`/promos/${promo._id}`, promo).subscribe((savedEvent) => {
                this.updatePromoInList(savedEvent);
                promo$.next(savedEvent);
            }, (error) => {
                this.setLastError('Saving Event', error.message, promo);
            });
        }
        return promo$.asObservable();
    }
}
PromosService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PromosService_Factory() { return new PromosService(i0.ɵɵinject(i1.ApiService)); }, token: PromosService, providedIn: "root" });
