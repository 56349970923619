import { Subject } from 'rxjs';
import { ApiService } from './api.service';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
export class ImportService {
    constructor(apiService) {
        this.apiService = apiService;
    }
    importProducers() {
        const result$ = new Subject();
        this.apiService.post(`/import/producersapi`, {}).subscribe((data) => {
            result$.next({ success: true, data });
        }, (error) => {
            result$.next({ success: false, error });
            result$.complete();
        }, () => {
            result$.complete();
        });
        return result$.asObservable();
    }
    importMerchants() {
        const result$ = new Subject();
        this.apiService.post(`/import/merchantsapi`, {}).subscribe((data) => {
            result$.next({ success: true, data });
        }, (error) => {
            result$.next({ success: false, error });
            result$.complete();
        }, () => {
            result$.complete();
        });
        return result$.asObservable();
    }
    importEvents() {
        const result$ = new Subject();
        this.apiService.post(`/import/eventsapi`, {}).subscribe((data) => {
            result$.next({ success: true, data });
        }, (error) => {
            result$.next({ success: false, error });
            result$.complete();
        }, () => {
            result$.complete();
        });
        return result$.asObservable();
    }
}
ImportService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ImportService_Factory() { return new ImportService(i0.ɵɵinject(i1.ApiService)); }, token: ImportService, providedIn: "root" });
