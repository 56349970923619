import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ApiService } from './api.service';

export interface ImportResult {
  success: boolean;
  data?: object;
  error?: {
    message: string;
    error: {
      message: string;
    };
  };
}

@Injectable({
  providedIn: 'root',
})
export class ImportService {
  constructor(private apiService: ApiService) {}

  importProducers(): Observable<ImportResult> {
    const result$: Subject<ImportResult> = new Subject<ImportResult>();
    this.apiService.post(`/import/producersapi`, {}).subscribe(
      (data) => {
        result$.next({ success: true, data });
      },
      (error) => {
        result$.next({ success: false, error });
        result$.complete();
      },
      () => {
        result$.complete();
      }
    );

    return result$.asObservable();
  }

  importMerchants(): Observable<ImportResult> {
    const result$: Subject<ImportResult> = new Subject<ImportResult>();
    this.apiService.post(`/import/merchantsapi`, {}).subscribe(
      (data) => {
        result$.next({ success: true, data });
      },
      (error) => {
        result$.next({ success: false, error });
        result$.complete();
      },
      () => {
        result$.complete();
      }
    );

    return result$.asObservable();
  }

  importEvents(): Observable<ImportResult> {
    const result$: Subject<ImportResult> = new Subject<ImportResult>();
    this.apiService.post(`/import/eventsapi`, {}).subscribe(
      (data) => {
        result$.next({ success: true, data });
      },
      (error) => {
        result$.next({ success: false, error });
        result$.complete();
      },
      () => {
        result$.complete();
      }
    );

    return result$.asObservable();
  }
}
